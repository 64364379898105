import { LinkListIcon } from '@local/web-design-system';
import { IntlShape, MessageDescriptor } from 'react-intl';
import { Subscription } from 'state-domains/domain';

import { PathConstants } from './pathConstants';

export const titles = {
    home: { id: 'home.title', defaultMessage: 'Home' },
    projects: { id: 'projects.title', defaultMessage: 'Projects' },
    events: { id: 'events.title', defaultMessage: 'Events' },
    jobs: { id: 'events.jobs.title', defaultMessage: 'Jobs' },
    notifications: { id: 'notifications.title', defaultMessage: 'Notifications' },
    configuration: { id: 'configuration.title', defaultMessage: 'Site Settings' },
    configurationLogging: { id: 'configuration.logging.title', defaultMessage: 'Logging' },
    configurationActivitiesProjects: {
        id: 'configuration.activitiesAndProjects.title',
        defaultMessage: 'Activities and Projects',
    },
    configurationSampling: { id: 'configuration.sampling.title', defaultMessage: 'Sampling' },
    configurationAccount: { id: 'configuration.account.title', defaultMessage: 'Account' },
    configurationIntegrations: {
        id: 'configuration.integrations.title',
        defaultMessage: 'Integrations | Evo',
    },
};

export const routeTitle = (route: keyof typeof titles): MessageDescriptor => titles[route] || '';

export const routeIcons = {
    home: 'dashboard',
    projects: 'project',
    events: 'events',
    configuration: 'integrations',
};

const baseNavObject = {
    reloadComponent: false,
    relatedPaths: [],
    nonRelatedPaths: [],
};

export const mxdNavItems = (subscriptionObject: Subscription | null, intl: IntlShape) => [
    {
        ...baseNavObject,
        key: 'home',
        text: intl.formatMessage(titles.home),
        location: PathConstants.HOME.ROOT,
        icon: routeIcons.home as LinkListIcon,
    },
    {
        ...baseNavObject,
        key: 'projects',
        text: intl.formatMessage(titles.projects),
        location: PathConstants.PROJECTS.ROOT,
        icon: routeIcons.projects as LinkListIcon,
    },
    {
        ...baseNavObject,
        key: 'events',
        text: intl.formatMessage(titles.events),
        location: PathConstants.EVENTS.ROOT,
        icon: routeIcons.events as LinkListIcon,
    },
    ...(subscriptionObject?.role === 'admin'
        ? [
              {
                  ...baseNavObject,
                  key: 'configuration',
                  text: intl.formatMessage(titles.configuration),
                  location: PathConstants.CONFIGURATION.ROOT,
                  icon: routeIcons.configuration as LinkListIcon,
              },
          ]
        : []),
];
